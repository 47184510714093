<template>
  <svg width="24px" height="24px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="url(#admin-gradient)">
    <path
      d="M12 14v8H4a8 8 0 0 1 8-8zm0-1c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6zm9 4h1v5h-8v-5h1v-1a3 3 0 0 1 6 0v1zm-2 0v-1a1 1 0 0 0-2 0v1h2z"
    ></path>
    <defs>
      <linearGradient id="admin-gradient" x1="21" y1="12.6266" x2="3" y2="12.6266" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F0899E" />
        <stop offset="0.244792" stop-color="#9617D2" />
        <stop offset="0.510417" stop-color="#4E0FFF" />
        <stop offset="0.75" stop-color="#2C60FD" />
        <stop offset="1" stop-color="#30F0F6" />
      </linearGradient>
    </defs>
  </svg>
</template>
