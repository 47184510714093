<script setup>
const colorMode = useColorMode();
const isLightMode = computed(() => {
  return colorMode.preference === "light" || colorMode.value === "light";
});
const isDarkMode = computed(() => {
  return colorMode.preference === "dark" || colorMode.value === "dark";
});
</script>

<template>
  <div class="custom-gradient relative flex h-9 w-24 items-center rounded-full">
    <HeadlessSwitch
      tag="div"
      aria-label="toggle theme"
      :class="[
        'relative flex h-9 w-full shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent bg-transparent',
      ]"
      @click="
        $colorMode.preference === 'light' || $colorMode.preference === 'system'
          ? ($colorMode.preference = 'dark')
          : ($colorMode.preference = 'light')
      "
    >
      <div
        class="pointer-events-none relative inline-block size-6 rounded-full bg-transparent ring-0 transition duration-200 ease-in-out"
      >
        <span
          :class="[
            isLightMode ? 'opacity-100' : 'opacity-40',
            'absolute inset-0 ml-2 flex size-full items-center justify-center transition-opacity duration-200 ease-in-out',
          ]"
          aria-hidden="true"
        >
          <IconSun class="size-6"></IconSun>
        </span>
        <span
          :class="[
            isDarkMode ? 'opacity-100' : 'opacity-40',
            'absolute inset-0 flex size-full translate-x-[60px] items-center justify-center transition-opacity duration-200 ease-in-out',
          ]"
          aria-hidden="true"
        >
          <IconMoon class="size-6"></IconMoon>
        </span>
      </div>
    </HeadlessSwitch>
  </div>
</template>
