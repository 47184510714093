<template>
  <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21ZM11.5 23C17.8513 23 23 17.8513 23 11.5C23 5.14873 17.8513 0 11.5 0C5.14873 0 0 5.14873 0 11.5C0 17.8513 5.14873 23 11.5 23Z"
      fill="url(#paint0_linear_10391_402100)"
      class="fill-blue-primary dark:fill-white sm:fill-[url(#paint0_linear_10391_402100)] sm:dark:fill-[url(#paint0_linear_10391_402100)]"
    />
    <path
      d="M11.0529 10.8305C11.8152 10.0678 12.3344 9.09636 12.5448 8.03879C12.7552 6.98121 12.6474 5.885 12.235 4.88867C13.4108 5.12015 14.4908 5.6971 15.3369 6.54574C17.7028 8.91167 17.7028 12.7481 15.3369 15.114C12.9705 17.4805 9.1348 17.4799 6.76834 15.114C5.91995 14.2679 5.34305 13.1881 5.11133 12.0125C6.10763 12.4249 7.20379 12.5326 8.26132 12.3222C9.31885 12.1118 10.2903 11.5927 11.0529 10.8305Z"
      fill="url(#moon_gradient)"
      class="fill-blue-primary dark:fill-white sm:fill-[url(#moon_gradient)] dark:sm:fill-[url(#moon_gradient)]"
    />
    <defs>
      <linearGradient
        id="paint0_linear_10391_402100"
        x1="0.480739"
        y1="22.5285"
        x2="22.5378"
        y2="22.5285"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2FECF6" />
        <stop offset="0.333333" stop-color="#4425FE" />
        <stop offset="0.640625" stop-color="#8214DE" />
        <stop offset="0.994792" stop-color="#E980A1" />
      </linearGradient>
      <linearGradient
        id="moon_gradient"
        x1="11.77"
        y1="11.3007"
        x2="-14.7459"
        y2="0.602354"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#07FBF9" />
        <stop offset="0.317708" stop-color="#4A85E8" />
        <stop offset="0.572917" stop-color="#A61C90" />
      </linearGradient>
    </defs>
  </svg>
</template>
