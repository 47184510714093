<script setup>
defineProps({
  open: {
    type: Boolean,
    default: false,
  },
});

const $emit = defineEmits(["closeSideBar"]);
</script>

<template>
  <HeadlessTransitionRoot as="template" :show="open">
    <HeadlessDialog as="div" class="relative z-[15]" @close="$emit('closeSideBar', false)">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full md:pl-10">
            <HeadlessTransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <HeadlessDialogPanel class="pointer-events-auto w-screen max-w-full md:max-w-md">
                <div
                  class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl dark:bg-[#0F2260] dark:shadow-[#00bbba]"
                >
                  <div class="flex h-7 items-center pl-9 pr-4 sm:pr-6">
                    <AtomButton
                      aria-label="Close"
                      type="button"
                      class="rounded-md text-gray-400 hover:text-gray-500"
                      @click="$emit('closeSideBar', false)"
                    >
                      <span class="sr-only">Close panel</span>
                      <IconArrowGradient
                        class="size-9 -rotate-90 stroke-blue-primary dark:stroke-white"
                        aria-hidden="true"
                      />
                    </AtomButton>
                  </div>
                  <div class="relative mt-6 flex-1 px-4 sm:px-6">
                    <slot />
                  </div>
                </div>
              </HeadlessDialogPanel>
            </HeadlessTransitionChild>
          </div>
        </div>
      </div>
    </HeadlessDialog>
  </HeadlessTransitionRoot>
</template>
