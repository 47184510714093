<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="url(#transaction-gradient)" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5 7C19.15 7 20.5 8.35 20.5 10L20.5 15C20.5 16.65 19.15 18 17.5 18L17.5 20C20.25 20 22.5 17.75 22.5 15L22.5 10C22.5 7.25 20.25 5 17.5 5L11.9 5L11.9 7L17.5 7Z"
    />
    <path d="M12.8 4.10156L8.80005 6.10156L12.8 8.10156L12.8 4.10156Z" />
    <path
      d="M6.5 18C4.85 18 3.5 16.65 3.5 15L3.5 10C3.5 8.35 4.85 7 6.5 7L6.5 5C3.75 5 1.5 7.25 1.5 10L1.5 15C1.5 17.75 3.75 20 6.5 20L12.5 20L12.5 18L6.5 18Z"
    />
    <path d="M11.2 16.8984L15.2 18.8984L11.2 20.8984L11.2 16.8984Z" />
    <path
      d="M9.77976 15.2967C9.77058 15.2967 9.76673 15.2916 9.76258 15.2843L8.80005 12.4227C8.80005 12.4176 8.80123 12.4126 8.80657 12.4089L11.4847 9.7043C11.4883 9.70119 11.4927 9.6995 11.4977 9.69922L14.7829 9.70176C14.7936 9.70176 14.8013 9.71051 14.8013 9.72067C14.8013 9.72321 14.8013 9.72688 14.7974 9.73083L10.43 14.4225C10.4244 14.4279 10.4209 14.4355 10.4209 14.4437C10.4209 14.4663 10.4499 14.4852 10.4736 14.4674L12.7476 12.7363C12.7583 12.7312 12.7675 12.7262 12.7832 12.7239L15.9827 12.69C15.9934 12.69 16.0011 12.6988 15.9999 12.7089C15.9999 12.7126 15.9999 12.7177 15.9961 12.7214L13.2904 15.293C13.2871 15.2964 13.2824 15.2987 13.2773 15.2992L9.77976 15.2967Z"
    />
    <defs>
      <linearGradient id="transaction-gradient" x1="21" y1="12.6266" x2="3" y2="12.6266" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F0899E" />
        <stop offset="0.244792" stop-color="#9617D2" />
        <stop offset="0.510417" stop-color="#4E0FFF" />
        <stop offset="0.75" stop-color="#2C60FD" />
        <stop offset="1" stop-color="#30F0F6" />
      </linearGradient>
    </defs>
  </svg>
</template>
