<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_15087_64970)">
      <path
        d="M11 22C4.92456 22 0 17.0754 0 11C0 4.92456 4.92456 0 11 0C17.0754 0 22 4.92456 22 11C22 17.0754 17.0754 22 11 22ZM8.32975 9.90275L11 7.2325L13.6716 9.90413L15.2254 8.35038L11 4.125L6.776 8.349L8.32975 9.90275ZM4.125 11L5.67875 12.5538L7.2325 11L5.67875 9.44625L4.125 11ZM8.32975 12.0972L6.77394 13.6489L6.776 13.651L11 17.875L15.2254 13.6496V13.6489L13.6716 12.0952L11 14.7675L8.32975 12.0972ZM14.7675 11L16.3212 12.5538L17.875 11L16.3212 9.44625L14.7675 11ZM12.5758 10.9986H12.5764L11 9.42288L9.83469 10.5875L9.70062 10.7216L9.42494 10.9979L9.42219 11L9.42494 11.0021L10.9993 12.5771L12.5764 11.0007L12.5771 11L12.5758 10.9993V10.9986Z"
        fill="#F3BA2F"
      />
    </g>
    <defs>
      <clipPath id="clip0_15087_64970">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
